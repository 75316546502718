<template lang="pug">
  v-dialog(v-model="showCenterLoading" persistent width="300")
    v-card(color="primary white--text")
        v-card-text(class="white--text") Aguarde...
          v-progress-linear(:active="showCenterLoading" indeterminate color="white")
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: mapGetters(['showCenterLoading'])
}
</script>
