import axios from 'axios'

export default {
  adminLogin(payload) {
    return axios.get(`/admin/login/${payload.eventId}`)
  },
  userLogin(payload) {
    return axios.post('/login', payload)
  },
  refreshToken(payload) {
    return axios.post('/refresh-token/', payload)
  }
}
