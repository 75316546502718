import { render, staticRenderFns } from "./SuccessMessage.vue?vue&type=template&id=cbd843fa&scoped=true&lang=pug&"
import script from "./SuccessMessage.vue?vue&type=script&lang=js&"
export * from "./SuccessMessage.vue?vue&type=script&lang=js&"
import style0 from "./SuccessMessage.vue?vue&type=style&index=0&id=cbd843fa&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cbd843fa",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
installComponents(component, {VBtn,VCol,VListItem,VListItemContent,VListItemTitle,VRow,VSnackbar})
