export default [
  {
    path: '/',
    name: 'landing',
    meta: {
      requiresAuth: true
    },
    component: () =>
      import(/* webpackChunkName: "landing" */ '@/components/Dashboard.vue')
  }
]
