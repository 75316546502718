<template lang="pug">
  v-snackbar(v-show="showSuccessMessage" v-model="showSuccessMessage" color="success" bottom timeout="2000" app)
    v-row(dense)
      v-col(class="text-center")
        v-list-item(dense)
          v-list-item-content
            v-list-item-title
              span(class="body-1") {{ successMessage }}
        v-btn(text @click="showSuccessMessage = false" class="caption") Fechar
</template>

<script>
import * as types from '@/store/mutation-types'

export default {
  name: 'SuccessMessage',
  computed: {
    showSuccessMessage: {
      get() {
        return this.$store.state.success.showSuccessMessage
      },
      set(value) {
        this.$store.commit(types.SHOW_SUCCESS, value)
      }
    },
    successMessage() {
      if (this.$store.state.success.successMessageParams) {
        return this.$i18n.t(this.$store.state.success.successMessage, [
          ...this.$store.state.success.successMessageParams
        ])
      }
      return this.$i18n.t(this.$store.state.success.successMessage)
    },
    successMessageTimeout() {
      return this.$store.state.success.successMessageTimeout
    }
  },
  watch: {
    successMessage() {
      setTimeout(() => {
        this.showSuccessMessage = this.successMessage !== ''
      }, 100)
    }
  }
}
</script>

<style scoped>
  div.v-snack:not(.v-snack--absolute) { /* correção para posicionar msg de erro no iOs */
    height: 100%;
  }
</style>
