<template lang="pug">
  v-progress-linear(:active="showTopLoading" indeterminate absolute bottom color="primary")
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: mapGetters(['showTopLoading'])
}
</script>
