<template lang="pug">
  v-snackbar(v-show="showErrorMessage" v-model="showErrorMessage" color="error" bottom timeout="3000" app)
    v-row(dense)
      v-col(class="text-center")
        v-list-item(dense)
          v-list-item-content
            v-list-item-title(v-for="(item, index) in error" :key="index")
              span(class="body-1") {{ item }}
        v-btn(text @click="showErrorMessage = false" class="caption") Fechar
</template>

<script>
import * as types from '@/store/mutation-types'
import { formatErrorMessages } from '@/utils/utils.js'

export default {
  name: 'ErrorMessage',
  computed: {
    showErrorMessage: {
      get() {
        return this.$store.state.error.showErrorMessage
      },
      set(value) {
        this.$store.commit(types.SHOW_ERROR, value)
      }
    },
    error() {
      return formatErrorMessages('errors', this.$store.state.error.errorMessage)
    }
  },
  watch: {
    error() {
      setTimeout(() => {
        this.showErrorMessage = this.error !== null
      }, 100)
    }
  }
}
</script>
<style scoped>
  div.v-snack:not(.v-snack--absolute) { /* correção para posicionar msg de erro no iOs */
    height: 100%;
  }
</style>
